<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.m_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="addDialogVisible = true" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>

      <!--   用户列表区域   -->
      <el-table :data="userlist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="姓名" prop="m_name"></el-table-column>
        <el-table-column label="邮箱" prop="m_email"></el-table-column>
        <el-table-column label="电话" prop="m_phone"></el-table-column>
        <el-table-column label="职位" prop="m_position"></el-table-column>
<!--        <el-table-column label="角色" prop="role_name"></el-table-column>-->
        <el-table-column label="状态" prop="m_status">
          <template slot-scope="scope">
            <!--{{scope.row.m_status}}-->
            <el-switch v-model="scope.row.m_status" @change="userStateChanged(scope.row)" :disabled="is_editStatus"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260px">
          <template slot-scope="scope">
            <!-- 修改 -->
<!--            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="is_distributionRole">-->

            <el-button type="primary"  size="mini" icon="el-icon-edit"
                       @click="showEditDialog(scope.row.m_id)" v-if="is_edit"></el-button>
<!--            </el-tooltip>-->
            <!--删除-->
            <el-button type="danger"  size="mini" v-if="is_del" icon="el-icon-delete-solid"
                       @click="removeUserById(scope.row.m_id)"></el-button>
            <!--分配角色-->
            <el-tooltip effect="dark" content="分配角色" placement="top">

            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="分配角色" placement="top" v-if="is_distributionRole">
              <el-button type="warning" size="mini" icon="el-icon-s-tools"
                         @click="role(scope.row.m_id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </el-card>
    <!-- 添加用户的对话框   -->
    <el-dialog
        title="添加用户" :visible.sync="addDialogVisible" width="60%" @close="addDialogClosed">
      <!--      内容主题区-->
      <el-form :model="addForm" :rules="addFormRules" :label-position="labelPosition"
               ref="addFormRef" label-width="100px" >
        <el-form-item label="用户名" prop="m_account">
          <el-input v-model="addForm.m_account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="m_password">
          <el-input v-model="addForm.m_password"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="m_name">
          <el-input v-model="addForm.m_name"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="m_email">
          <el-input v-model="addForm.m_email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="m_phone">
          <el-input v-model="addForm.m_phone"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="m_position">
          <el-input v-model="addForm.m_position"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人" prop="m_department">
          <el-select v-model="addForm.m_department" placeholder="请选择">
            <el-option
                v-for="item in chargelist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QQ" prop="m_qq">
          <el-input v-model="addForm.m_qq"></el-input>
        </el-form-item>
        <el-form-item label="微信" prop="m_wechat">
          <el-input v-model="addForm.m_wechat"></el-input>
        </el-form-item>
        <el-form-item label="选择部门" prop="d_id">
          <el-cascader
              v-model="addselectListarea"
              :options="DepartmentList" :props="cascaderProps"
              @change="addhandleChange"></el-cascader>
        </el-form-item>
      </el-form>
      <!--      底部区-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>

    <!--  修改用户的对话框  -->
    <el-dialog
        title="修改用户信息" :visible.sync="editDialogVisible" width="60%" @close="editDialogClosed">
      <!--      内容主题区-->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"
               label-width="100px" :label-position="labelPosition" >
        <el-form-item label="用户名">
          <el-input v-model="editForm.m_account" disabled></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="m_name">
          <el-input v-model="editForm.m_name"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="m_email">
          <el-input v-model="editForm.m_email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="m_phone">
          <el-input v-model="editForm.m_phone"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="m_position">
          <el-input v-model="editForm.m_position"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人" prop="m_department">
          <el-select v-model="editForm.m_department" placeholder="请选择">
            <el-option
                v-for="item in chargelist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QQ" prop="m_qq">
          <el-input v-model="editForm.m_qq"></el-input>
        </el-form-item>
        <el-form-item label="微信" prop="m_wechat">
          <el-input v-model="editForm.m_wechat"></el-input>
        </el-form-item>
        <el-form-item label="选择部门" prop="d_id">
          <el-cascader
              v-model="editselectListarea"
              :options="DepartmentList" :props="cascaderProps"
              @change="edithandleChange"></el-cascader>
        </el-form-item>
      </el-form>
      <!--      底部区-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!--  分配角色的对话框  -->
    <el-dialog
        title="修改用户角色" :visible.sync="roleDialog" width="60%">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="item in rolelist" @change="getcheckList"
                         :key="item.r_id"
                         :label="item.r_id">{{item.r_name}}</el-checkbox>
          </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="RoleInfo" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    // 验证邮箱验证规则
    var checkm_email = (rule, value, cb) => {
      const regEamil = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEamil.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    //验证手机号验证规则
    var checkm_phone = (rule, value, cb) => {
      const regm_phone = /^(0|86+17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regm_phone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      addloading:false,
      editloading:false,
      labelPosition:'left',
      roleDialog:false,
      rolevalue:'',
      //获取用户列表的参数对象
      querInfo: {
        page: 1,
        limit: 10,
        m_name:''
      },
      //页面权限
      UserJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      is_distributionRole:false,
      is_editStatus:true,
      //表格加载
      loading:true,
      chargelist:[
        {id:1,name:'否'},
        {id:2,name:'是'}
      ],
      userlist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      //添加用户表单数据
      addForm: {
        m_account: '',
        m_password: '',
        m_email: '',
        m_phone: '',
        d_id:'',
        m_name:'',
        m_position:'',
        m_department:'',
        m_qq:'',
        m_wechat:''
      },
      //添加表单验证规则对象
      addFormRules: {
        m_account: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 15, message: '用户名的长度在3-15个字符之间', trigger: 'blur'}
        ],
        m_password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '密码的长度在6-15个字符之间', trigger: 'blur'}
        ],
        // m_email: [
        //   {required: true, message: '请输入邮箱', trigger: 'blur'},
        //   {validator: checkm_email, trigger: 'blur'}
        // ],
        m_phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {validator: checkm_phone, trigger: 'blur'}
        ],
        m_name:[
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        // m_position:[
        //   {required: true, message: '请输入职位', trigger: 'blur'},
        // ],
        // m_department:[
        //   {required: true, message: '请选择是否是部门负责人', trigger: 'change'},
        // ],
        // m_qq:[
        //   {required: true, message: '请输入QQ', trigger: 'blur'},
        // ],
        // m_wechat:[
        //   {required: true, message: '请输入微信', trigger: 'blur'},
        // ],
        // d_id:[
        //   {required: true, message: '请选择部门', trigger: 'change'},
        // ]

      },
      //控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      //查询到的用户信息对象
      editForm: {},
      //编辑表单验证规则对象
      editFormRules: {
        m_account: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 15, message: '用户名的长度在3-15个字符之间', trigger: 'blur'}
        ],
        m_password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '密码的长度在6-15个字符之间', trigger: 'blur'}
        ],
        // m_email: [
        //   {required: true, message: '请输入邮箱', trigger: 'blur'},
        //   {validator: checkm_email, trigger: 'blur'}
        // ],
        m_phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {validator: checkm_phone, trigger: 'blur'}
        ],
        m_name:[
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        // m_position:[
        //   {required: true, message: '请输入职位', trigger: 'blur'},
        // ],
        // m_department:[
        //   {required: true, message: '请选择是否是部门负责人', trigger: 'change'},
        // ],
        // m_qq:[
        //   {required: true, message: '请输入QQ', trigger: 'blur'},
        // ],
        // m_wechat:[
        //   {required: true, message: '请输入微信', trigger: 'blur'},
        // ],
        // d_id:[
        //   {required: true, message: '请选择部门', trigger: 'change'},
        // ]

      },
      DepartmentList:[],
      addselectListarea:[],
      editselectListarea:[],
      cascaderProps: {
        expandTrigger: 'hover',
        value: 'd_id',
        label: 'd_name',
        children: 'children'
      },
      //所有角色
      rolelist:[],
      //选中角色的数组
      checkList: [],
      //m_id
      m_id:'',
    }
  },
  created() {
    this.getUserList()
    this.getDepartmentList()
    this.getRoleList()
    this.getUserJurisdiction()
  },
  methods: {
    //获取权限接口
    async getUserJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.UserJurisdiction=res.data
      this.UserJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='distributionRole'){
          //分配权限
          this.is_distributionRole=true
        }else if(item.m_path=='editStatus'){
          //使用状态
          this.is_editStatus=false
        }
      })
    },
    async getUserList() {
      this.loading=true
      const {data: res} = await this.$http.get('member/getList',
          {params: this.querInfo})
      if (res.code !== 200) return this.$message.error('获取用户列表失败！')
      this.userlist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    async getDepartmentList() {
      const {data: res} = await this.$http.get('department/getAllList', )
      if (res.code !== 200) return this.$message.error('获取部门列表失败！')
      this.DepartmentList = res.data
    },
    //添加 监听级联选择器 选中节点变化时触发
    addhandleChange(){
      this.addForm.d_id=this.addselectListarea[1]
    },
    //编辑 监听级联选择器 选中节点变化时触发
    edithandleChange(){
      this.editForm.d_id=this.editselectListarea[1]
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getUserList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getUserList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getUserList()
    },
    //
    async userStateChanged(userinfo) {
      const {data: res} = await this.$http.post(`member/editStatus`,{
        'm_id':userinfo.m_id,
        'm_status':userinfo.m_status
      })
      if (res.code != 200) {
        userinfo.m_status = !userinfo.m_status
        return this.$message.error('更新用户状态失败')
      }
      this.$message.success('更新用户状态成功')
      this.getUserList()
    },
    //监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
      this.addselectListarea=''
    },
    //点击按钮添加新用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        //可以发起添加新用户的请求
        const {data: res} = await this.$http.post('member/add', this.addForm)
        this.addloading=false
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success('添加用户成功')
        this.addDialogVisible = false
        this.getUserList()
      })
    },
    //展示编辑用户的对话框
    async showEditDialog(id) {
      const {data: res} = await this.$http.get(`member/find/${id}`)
      if (res.code !== 200) {
        return this.$message.error('获取用户失败')
      }
      this.editForm = res.data
      this.editselectListarea=res.data.department
      // this.$message.success('修改用户信息成功')

      this.editDialogVisible = true
    },
    //监听修改用户对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.editloading=false
    },
    //修改用户并提交
    editUserInfo() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        //发起修改用户请求
        const {data: res} = await this.$http.post(`member/edit`,
          this.editForm)
        this.editloading=false
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getUserList()
        //提示修改成功
        this.$message.success('更新用户信息成功')

      })
    },
    //根据id删除用户数据
    async removeUserById(id) {
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
          '此操作将永久删除该用户, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)

      //如果用户确认删除， 则返回值为字符串 confirm
      //如果用户取消删除，则返回值为字符串 cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data: res} = await this.$http.post(`member/del/${id}`)
      if (res.code !== 200) {
        return this.$message.error('删除用户失败')
      }
      this.$message.success('删除用户成功')
      this.getUserList()
    },
    //获取所有角色
    async getRoleList() {
      const {data: res} = await this.$http.get('role/getList', )
      if (res.code !== 200) return this.$message.error('获取角色列表失败！')
      this.rolelist = res.data.data
    },
    //role 分配角色
    async role(id){
      this.m_id=id
      const {data: res} = await this.$http.get(`member/getRole/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.roleDialog=true
      this.checkList=res.data
    },
    //
    getcheckList(){
    },
    async RoleInfo(){
      const {data:res} = await this.$http.post('member/distributionRole',
          {
            'm_id':this.m_id,
            rolelist:this.checkList
          })
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.roleDialog = false
    }

  }
}
</script>
<style lang="less" scoped>

</style>
